// import '@babel/polyfill';
import React from 'react';
import {createRoot} from 'react-dom/client';
import CombinedLoader from './Combined/CombinedLoader.jsx';
import './styles.scss';
import API from './Services/API.jsx';
import LoadingWidget from './LoadingWidget/index.jsx';
import ErrorWidget from './ErrorWidget/index.jsx';
import { ulid } from 'ulid';

import Quote from './Quote/Standalone';
import QuoteModal from './Quote/Modal';
import Portal from './Portal/Standalone';
import Payment from './Payment/Standalone';
import Documents from './Documents/Standalone';
import Chat from './Chat/ChatLoader';

import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import Callback from './Services/Callback.jsx';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';


let _gsQuoteRoot = null;
let _gsPayRoot = null;
let _gsPortalRoot = null;
let _gsCombinedRoot = null;
let _gsDocumentRoot = null;

window.getSoapy = new class {
    version = '1.3.0';

    status = {
        widgets: {
            quote: null,
            payment: null,
            portal: null,
            documents: null,
            combined: null,
        }
    };

    usingFAB = false;

    starter = new Callback();

    options = {
        defaultStyles: 'bg-light shadow rounded border p-3',
        businessId: null,
        visitorId: null,
        trackPageView: true,
        loadStyles: true,
        quoteElement: 'getsoapy-quote',
        payElement: 'getsoapy-pay',
        portalElement: 'getsoapy-portal',
        documentsElement: 'getsoapy-documents',
        combinedElement: 'getsoapy-combined',
        initialiseChat: false,
        hideFields: [],
        requireFields: [],
        completeInSections: false,
        sectionTitles: true,
        floatingActionButton: false,
        floatingTitle: 'Request a quote',
        onQuoteSubmitted: () => console.log('No onQuoteSubmitted Handler'),
        onQuoteNext: () => console.log('No onQuoteNext Handler'),
        stylesheet: 'https://embed.getsoapy.com/styles.css',
        scrollOffset: 0,
        apiBaseUrl: '',
        prefill: {},
        frequencies: [
            'Once',
            'Every 4 Weeks',
            'Every 8 Weeks',
            'Other',
        ]
    };

    optionSettingsMap = {
        hideFields: 'Quotes:HideFields',
        frequencies: 'Quotes:FrequencyChoices',
    }

    async init(businessId, options) {
        import('./Services/Sentry');

        this.options.businessId = businessId;

        this.mergeOptions(options);
        API.initialised.then(() => {
            this.mergeOptions(options, API.settings);
        });

        this.loadStyles();

        this.startListener();

        await this.initialize(businessId);

        this.getOrSetVisitorId();

        // Give the widgets a moment to initialise

        setTimeout(() => this.conditionallyOpenFloatingQuoteWidget(), 500);
        setTimeout(() => this.trackPageView(), 2000);
    }

    async initialize(businessId) {
        API.init(businessId, this.options.apiBaseUrl).then(() => {
            this.initializeWidgets()
        }).catch(err => {
            console.error(err)
            this.initializeWidgets(true)
        });
    }

    startQuote() {
        this.starter.invoke('quote');
    }

    startPortal() {
        this.starter.invoke('portal');
    }

    startPay() {
        this.starter.invoke('pay');
    }

    startDocuments() {
        this.starter.invoke('documents');
    }

    startListener() {
        window.addEventListener(
            'hashchange',
            () => {
                switch (location.hash) {
                    case '#quote':
                        this.startQuote();
                        break;
                    case '#portal':
                        this.startPortal();
                        break;
                    case '#pay':
                        this.startPay();
                        break;
                    default:
                        break;
                }
            },
            false,
          );
    }

    mergeOptions(options, settings) {
        console.log('settings', settings)
        if (typeof options !== 'object') {
            return
        }

        Object.keys(this.options).forEach(key => {
            // Get settings from the API
            const setting = this.getSettingForOption(key, settings);
            if (setting) {
                this.options[key] = setting;
            }

            // Get settings from the given options
            if (this.whatIs(options[key]) === 'string' && this.whatIs(this.options[key]) === 'array') {
                this.options[key] = options[key].split(',').map(f => f.trim());
            } else if (this.whatIs(options[key]) === this.whatIs(this.options[key])) {
                this.options[key] = options[key];
            }
        })
    }

    getSettingForOption(option, settings) {
        if (!settings) {
            return null;
        }

        const key = this.optionSettingsMap[option];
        if (!key) {
            return null;
        }

        const setting = settings.find(s => s.key === key);
        if (!setting) {
            return null;
        }

        return setting.value;
    }

    whatIs(value) {
        return Object.prototype.toString.call(value)
            .replace(/^\[object\s+([a-z]+)\]$/i, '$1')
            .toLowerCase();
    }

    getOrSetVisitorId() {
        if (this.options.visitorId) {
            return;
        }
        if (API.getCookie('visitor-id')) {
            this.options.visitorId = API.getCookie('visitor-id');
        }
        if (this.options.visitorId === null) {
            const visitorId = ulid();
            API.setCookie('visitor-id', visitorId, 30);
            this.options.visitorId = visitorId;
        }
    }

    getBusinessId() {
        return this.options.businessId;
    }

    loadStyles() {
        if (this.options.loadStyles === false) {
            return
        }

        const link = document.createElement('link');
        link.href = this.options.stylesheet;
        link.id = 'getSoapy-CSS';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.media = 'all';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    trackPageView() {
        if (this.options.trackPageView === false) {
            return
        }

        const data = {
            requestId: ulid(),
            visitorId: this.options.visitorId,
            businessId: this.options.businessId,
            host: window.location.host,
            path: window.location.pathname,
            experiments: null,
            userAgent: navigator ? navigator.userAgent : null,
            ip: null,
            timestamp: API.getCurrentDateTime(),
            referrerDomain: null,
            referrerUrl: document.referrer,
            referrer: null,
            gclid: this.extractQueryParam('gclid'),
            fbclid: this.extractQueryParam('fbclid'),
            utmSource: this.extractQueryParam('utm_source', this.extractQueryParam('utmSource')),
            utmCampaign: this.extractQueryParam('utm_campaign', this.extractQueryParam('utmCampaign')),
            utmMedium: this.extractQueryParam('utm_medium', this.extractQueryParam('utmMedium')),
            utmTerm: this.extractQueryParam('utm_term', this.extractQueryParam('utmTerm')),
            utmContent: this.extractQueryParam('utm_content', this.extractQueryParam('utmContent')),
            referral: null,
            widgets: this.status.widgets
        }

        API.trackPageView(data)
    }

    extractQueryParam(param, miss = null) {
        const vars = window.location.search.replace(/^\?/, '').split('&');
        for (var i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === param) {
                return decodeURIComponent(pair[1]);
            }
        }

        return miss;
    }

    widgetFound(widget, found) {
        if (this.status.widgets[widget] === undefined) {
            return;
        }
        if (found !== true && found !== false) {
            return;
        }
        this.status.widgets[widget] = found;
    }

    initializeWidgets(withError) {
        this.initializeQuoteWidget(withError);
        this.initializePaymentWidget(withError);
        this.initializePortalWidget(withError);
        this.initializeDocumentsWidget(withError);
        this.initializeCombinedWidget(withError);

        if (this.options.initialiseChat && !withError) {
            this.initialiseChatWidget();
        }
    }

    initializeQuoteWidget(withError) {
        const quoteElement = document.getElementById(this.options.quoteElement);
        const combinedElement = document.getElementById(this.options.combinedElement);

        if (quoteElement) {
            const style = quoteElement.getAttribute('x-style');
            const title = quoteElement.getAttribute('x-title');

            const widget = withError ?
                <ErrorWidget widgetStyle={style ?? this.options.defaultStyles} title={title} /> :
                <Quote options={this.options} attributes={{style, title}} />;

            _gsQuoteRoot = _gsQuoteRoot ?? createRoot(quoteElement);
            _gsQuoteRoot.render(widget);
            window.getSoapy.widgetFound('quote', true);

        } else if (combinedElement) {
            // Do nothing, but don't initialize the FAB

        } else if (this.options.floatingActionButton && this.options.businessId) {
            console.info('Initializing FAB');
            window.getSoapy.widgetFound('fab', true);
            this.initializeFAB();

        } else {
            console.info('No element found for Quote');
            window.getSoapy.widgetFound('quote', false);
        }
    }

    initializePaymentWidget(withError) {
        const payElement = document.getElementById(this.options.payElement);
        if (payElement) {
            const style = payElement.getAttribute('x-style');
            const title = payElement.getAttribute('x-title');
            const hideFields = payElement.getAttribute('x-hide-fields');

            let widget;
            if (withError) {
                widget = <ErrorWidget widgetStyle={style ?? this.options.defaultStyles} title={title} />;
            } else {
                widget = <Payment options={this.options} attributes={{style, title, hideFields}} />;
            }

            _gsPayRoot = _gsPayRoot ?? createRoot(payElement);
            _gsPayRoot.render(widget);
            window.getSoapy.widgetFound('payment', true);
        } else {
            console.info('No element found for Pay');
            window.getSoapy.widgetFound('payment', false);
        }
    }

    initializePortalWidget(withError) {
        const customerElement = document.getElementById(this.options.portalElement);
        if (customerElement) {
            const style = customerElement.getAttribute('x-style');
            const title = customerElement.getAttribute('x-title');

            let widget;

            if (withError) {
                widget = <ErrorWidget widgetStyle={style ?? this.options.defaultStyles} title={title} />;
            } else {
                widget = <Portal options={this.options} attributes={{style, title}} />;
            }

            _gsPortalRoot = _gsPortalRoot ?? createRoot(customerElement);
            _gsPortalRoot.render(widget);
            window.getSoapy.widgetFound('portal', true);
        } else {
            console.info('No element found for Customer portal');
            window.getSoapy.widgetFound('portal', false);
        }
    }

    initializeDocumentsWidget(withError) {
        const documentsElement = document.getElementById(this.options.documentsElement);
        if (documentsElement) {
            const style = documentsElement.getAttribute('x-style');
            const title = documentsElement.getAttribute('x-title');

            let widget;
            if (withError) {
                widget = <ErrorWidget widgetStyle={style ?? this.options.defaultStyles} title={title} />
            } else {
                widget = <Documents options={this.options} attributes={{style, title}} />;
            }

            _gsDocumentRoot = _gsDocumentRoot ?? createRoot(documentsElement);
            _gsDocumentRoot.render(widget);
            window.getSoapy.widgetFound('documents', true);
        } else {
            console.info('No element found for Documents');
            window.getSoapy.widgetFound('documents', false);
        }
    }

    initializeCombinedWidget(withError) {
        const combinedElement = document.getElementById(this.options.combinedElement);
        if (combinedElement) {
            const style = combinedElement.getAttribute('x-style');
            const title = combinedElement.getAttribute('x-title');
            const widget = withError ?
                <ErrorWidget widgetStyle={this.options.defaultStyles} /> :
                <CombinedLoader options={this.options} attributes={{style, title}} />

            _gsCombinedRoot = _gsCombinedRoot ?? createRoot(combinedElement);
            _gsCombinedRoot.render(widget);
            window.getSoapy.widgetFound('combined', true);
        } else {
            console.info('No element found for combined');
            window.getSoapy.widgetFound('combined', false);
        }
    }

    initialiseChatWidget() {
        const el = document.createElement('div');
        el.id = 'getsoapy-chat';
        el.className = 'getsoapy';

        document.getElementsByTagName('body')[0].appendChild(el);

        const widget = <Chat options={this.options} />

        _gsCombinedRoot = _gsCombinedRoot ?? createRoot(el);
            _gsCombinedRoot.render(widget);
            window.getSoapy.widgetFound('chat', true);

    }

    parseFrequencies(input) {
        if (!input) {
            return this.options.frequencies;
        }

        return input.split(',').map(f => f.trim());
    }

    initializeFAB() {
        const existing = document.getElementById('getsoapy-fab')
        if (existing) {
            return existing;
        }

        const fab = document.createElement('div');
        fab.id = 'getsoapy-fab';
        fab.className = 'getsoapy animate__animated animate__bounceInUp';

        let html = '<div id="getsoapy-fab-btn"><i class="fal fa-edit"></i></div>';
        html += '<div id="getsoapy-fab-label">Request a Quote</div>';
        fab.innerHTML = html;

        fab.addEventListener('click', () => this.openFloatingQuoteWidget())

        document.getElementsByTagName('body')[0].appendChild(fab);

        this.starter.listen((thing) => {
            if (thing === 'quote') {
                this.openFloatingQuoteWidget();
            }
        });

        return fab;
    }

    toggleFAB(show) {
        const fab = document.getElementById('getsoapy-fab');
        if (!fab) {
            return;
        }

        if (show) {
            fab.className = fab.className.replace('hidden', '');
        } else if (fab.className.indexOf('hidden') === -1) {
            fab.className += ' hidden';
        }
    }

    openFloatingQuoteWidget() {
        console.log('openFloatingQuoteWidget');
        this.toggleFAB(false);

        if (document.getElementById('getsoapy-floating-container')) {
            console.log('Already open, aborting');
            return;
        }

        const container = document.createElement('div');
        document.getElementsByTagName('body')[0].appendChild(container);

        const widget = <QuoteModal options={this.options} onClose={() => this.toggleFAB(true)} />;

        const root = createRoot(container);
        root.render(widget);
    }

    closeFloatingQuoteWidget(widget, container) {
        container.classList.add('animate__fadeOut', 'animate__faster');
        this.toggleFAB(true);

        setTimeout(() => {
            widget = null;
            document.getElementsByTagName('body')[0].removeChild(container);
        }, 1000);
    }

    conditionallyOpenFloatingQuoteWidget() {
        if (this.status.widgets['quote'] === true || this.status.widgets['combined'] === true) {
            return;
        }

        const matches = window.location.hash.match(/#?getsoapy-([a-zA-Z0-9]{26})-([a-zA-Z0-9]{50})/);
        if (!matches) {
            return;
        }

        this.openFloatingQuoteWidget();
    }
}();

/*
 * Setup loading widgets ASAP, even if getSoapy isn't initialized
 */
const quoteElement = document.getElementById(window.getSoapy.options.quoteElement);
if (quoteElement) {
    const style = quoteElement.getAttribute('x-style');
    const title = quoteElement.getAttribute('x-title');
    _gsQuoteRoot = createRoot(quoteElement);
    _gsQuoteRoot.render(<LoadingWidget widgetStyle={style ?? window.getSoapy.options.defaultStyles} title={title ?? 'Request a quote'} rows={6} />);
    window.getSoapy.widgetFound('quote', true);
} else {
    window.getSoapy.widgetFound('quote', false);
}

const payElement = document.getElementById(window.getSoapy.options.payElement);
if (payElement) {
    const style = payElement.getAttribute('x-style');
    const title = payElement.getAttribute('x-title');
    _gsPayRoot = createRoot(payElement);
    _gsPayRoot.render(<LoadingWidget widgetStyle={style ?? window.getSoapy.options.defaultStyles} title={title ?? 'Pay now'} rows={2} />);
    window.getSoapy.widgetFound('payment', true);
} else {
    window.getSoapy.widgetFound('payment', false);
}


const portalElement = document.getElementById(window.getSoapy.options.portalElement);
if (portalElement) {
    const style = portalElement.getAttribute('x-style');
    const title = portalElement.getAttribute('x-title');
    _gsPortalRoot = createRoot(portalElement);
    _gsPortalRoot.render(<LoadingWidget widgetStyle={style ?? window.getSoapy.options.defaultStyles} title={title ?? 'Customer Portal'} rows={4} />);
    window.getSoapy.widgetFound('portal', true);
} else {
    window.getSoapy.widgetFound('portal', false);
}


const combinedElement = document.getElementById(window.getSoapy.options.combinedElement);
if (combinedElement) {
    const style = combinedElement.getAttribute('x-style');
    const title = combinedElement.getAttribute('x-title');
    _gsCombinedRoot = createRoot(combinedElement);
    _gsCombinedRoot.render(<LoadingWidget widgetStyle={style ?? window.getSoapy.options.defaultStyles} title={title ?? 'getSoapy'} rows={2} />);
    window.getSoapy.widgetFound('combined', true);
} else {
    window.getSoapy.widgetFound('combined', false);
}


// We basically want to hide the documents widget until/unless there are documents to show
// Don't try and render it early
/*
const documentsElement = document.getElementById(window.getSoapy.options.documentsElement);
if (documentsElement) {
    const style = documentsElement.getAttribute('x-style');
    const title = documentsElement.getAttribute('x-title');
    _gsDocumentRoot = createRoot(documentsElement);
    _gsDocumentRoot.render(<LoadingWidget widgetStyle={style ?? window.getSoapy.options.defaultStyles} title={title ?? 'getSoapy'} rows={2} />);
    window.getSoapy.widgetFound('documents', true);
} else {
    window.getSoapy.widgetFound('documents', false);
}
*/
