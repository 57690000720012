import React, { useState, useEffect, useMemo, createContext, useContext, useRef } from 'react';
import API from '../../Services/API';
import Session from '../../Services/Session';
import usePreference from '../../Data/Preference';
import { ulid } from 'ulid';

const WidgetContext = createContext();

export const useWidgetContext = () => {
    return useContext(WidgetContext);
};

export const Provider = ({ children, options, attributes }) => {

    const [socketChannelId] = usePreference('socketChannelId', ulid(), true);
    const theAttributes = attributes ?? {};
    const [notification, setNotification] = useState(null);
    const [availableSession, setAvailableSession] = useState(Session.findAvailableSession());
    const [prefill, setPrefill] = useState(null);
    const widgetRef = useRef(null);
    const [smallScreen, setSmallScreen] = useState(false);
    const [experiments] = useState(options.experiments ?? {});

    const sessionData = useMemo(
        () => Session.getSessionData(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [availableSession]
    );

    useEffect(() => {
        setSmallScreen(widgetRef?.current?.clientWidth <= 576);
    }, [widgetRef?.current?.clientWidth])

    const providerValues = {
        socketChannelId,
        smallScreen,
        // widgetStyle,
        frequencies: options.frequencies,
        completeInSections: options.completeInSections,
        hideFields: theAttributes?.hideFields ?? options.hideFields,

        widgetRef,
        scrollOffset: theAttributes.scrollOffset ?? options.scrollOffset,
        prefill: prefill ?? options.prefill,
        setPrefill,

        options,
        attributes: theAttributes,

        widgetTitle: theAttributes.title ?? options.title ?? null,
        widgetStyle: theAttributes.style ?? options.defaultStyles ?? '',

        onQuoteSubmitted: options.onQuoteNext,
        onQuoteNext: options.onQuoteNext,

        services: API.services,
        business: API.business,
        stripeId: API.stripeId,
        stripeKey: API.stripeKey,
        testMode: API.testMode,
        paymentMethods: API.paymentMethods,
        bankDetails: API.bankDetails,
        acceptTips: API.acceptTips,
        descriptor: API.descriptor,
        documents: API.documents,
        features: API.features,
        location: API.location,

        experiments,
        getExperiment: (name) => experiments[name] ?? null,

        hasFeature: (feature) => API.hasFeature(feature),

        notification,
        showError: (err, title = null) => setNotification({type: 'error', title: title, message: err ? API.parseErrorResponse(err) : null}),
        showSuccess: (msg, title = null) => setNotification({ type: 'success', title: title, message: msg }),

        availableSession,
        sessionData: sessionData,
        setAvailableSession,
        persistSession: (model, data) => {
            Session.persist(model, data);
            setAvailableSession(Session.findAvailableSession());
        },
        clearSession: () => {
            Session.clearSession();
            setAvailableSession(null);
        },

        startListener: (listeningForThing, callback) => {
            return window.getSoapy.starter.listen((startedThing) => {
                if (listeningForThing === startedThing) {
                    callback(startedThing);
                }
            });
        },

        isLocal: window.location.port > 1000,
        visitorId: window.getSoapy.options.visitorId,
    };

    return (
        <WidgetContext.Provider value={providerValues}>
            {children}
        </WidgetContext.Provider>
    );
};
