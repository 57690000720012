import React from 'react';
import Widget from '../Components/Widget';
import DocumentsLoader from './DocumentsLoader';
import API from '../Services/API';

const Standalone = ({options, attributes}) => {

    if (API.hasFeature('DOCUMENTS') === false || API.documents.length === 0) {
        return null;
    }

    return <Widget {...{options, attributes}} focusOnStart={'documents'}>
        <DocumentsLoader />
    </Widget>
}

export default Standalone;
