import React, { Suspense, lazy } from 'react';
import { PageLoading } from '../Components/Loading';

const Chat = lazy(() => import(/* webpackPreload: true, webpackChunkName: "chat" */'./Chat'));

const ChatLoader = (props) => {
    return <Suspense fallback={<PageLoading />}>
        <Chat {...props} />
    </Suspense>
}

export default ChatLoader;
