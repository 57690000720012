import { useState } from 'react';

const usePreference = (key, def, session = false) => {
    const storage = session ? window.sessionStorage : window.localStorage;

    let existingVal = def;
    if (storage.getItem(key) !== null) {
        try {
            existingVal = JSON.parse(storage.getItem(key));
        } catch {
            existingVal = storage.getItem(key);
        }
    } else if (def) {
        storage.setItem(key, JSON.stringify(def));
    }

    const [value, setValue] = useState(existingVal);

    const set = (val) => {
        storage.setItem(key, JSON.stringify(val));
        setValue(val);
    }

    return [
        value,
        set,
    ];
}

export default usePreference;
